'use client';

import { textVariant } from '@/src/lib/motion';
import { m } from 'framer-motion';

import { SITE } from '@/src/config';
import { Separator } from '../ui/separator';

import MotionWrapper from '@/src/lib/motionWrapper';
import { classesData } from '@/src/shared/data';
import { ClassItem } from '@/src/shared/types';
import Image from 'next/image';
import CalButton from './CalButton';
import calendarIconYellow from '/public/vectors/calendar-icon-yellow.svg';
import clockIcon from '/public/vectors/clock-icon.svg';
import mapPinIcon from '/public/vectors/map-pin-icon.svg';
import ticketIcon from '/public/vectors/ticket-icon.svg';

const ClassesTable = () => {
	return (
		<MotionWrapper>
			<m.div
				variants={textVariant(0.3)}
				initial="hidden"
				whileInView="show"
				viewport={{ once: true }}
			>
				<div className="relative hidden overflow-x-auto rounded-lg shadow-md sm:block">
					<table className="w-full text-left text-gray-400">
						<thead className="border-b border-gray-600 bg-black font-recursive text-xs font-semibold uppercase">
							<tr className="uppercase">
								<th scope="col" className="px-6 py-3">
									Day
								</th>
								<th scope="col" className="px-6 py-3">
									Time
								</th>
								<th scope="col" className="px-6 py-3">
									Venue
								</th>
								<th scope="col" className="px-6 py-3">
									Membership
								</th>
								<th
									scope="col"
									className="whitespace-nowrap px-6 py-3"
								>
									Free Trial Class
								</th>
							</tr>
						</thead>

						<tbody className="font-recursive">
							{classesData.map(
								(classItem: ClassItem, index: number) => (
									<tr
										key={index + 'dt'}
										className="border-b border-divider bg-black text-sm transition-colors duration-300 hover:bg-charcoal"
									>
										<td className="px-6 py-4 font-bold uppercase text-yellow-primary">
											{classItem.day}
										</td>
										<td className="whitespace-nowrap px-6 py-4 font-bold uppercase text-yellow-primary">
											{classItem.time}
										</td>
										<td className="text-pretty px-6 py-4 font-medium text-white">
											{classItem.venue}
										</td>
										<td className="px-6 py-4 font-normal text-white">
											{classItem.price}
										</td>
										<td className="whitespace-nowrap px-6 py-4 font-normal">
											<CalButton
												link={classItem.calEventLink}
												namespace={
													classItem.calEventNamespace
												}
											/>
										</td>
									</tr>
								)
							)}
						</tbody>
					</table>
				</div>

				{classesData.map((classItem: ClassItem, index: number) => (
					<div
						key={index + 'm'}
						className="relative flex flex-col items-start gap-y-3 overflow-x-auto rounded-lg bg-black px-4 py-6 font-recursive shadow-md transition-colors duration-300 hover:bg-charcoal sm:hidden"
					>
						<ul className="space-y-4 text-left text-sm">
							<li className="flex items-center space-x-3">
								<Image
									src={calendarIconYellow}
									alt="Calendar icon"
									aria-hidden="true"
									className="size-6 shrink-0"
								/>
								<span className="font-bold uppercase text-yellow-primary">
									Sundays
								</span>
							</li>
							<li className="flex items-center space-x-3">
								<Image
									src={clockIcon}
									alt="Clock icon"
									aria-hidden="true"
									className="size-6 shrink-0"
								/>
								<span className="font-bold uppercase text-yellow-primary">
									10:00–12:00
								</span>
							</li>
							<li className="flex items-center space-x-3">
								<Image
									src={mapPinIcon}
									alt="Map icon"
									aria-hidden="true"
									className="size-6 shrink-0"
								/>
								<span className="text-pretty font-semibold text-white">
									{SITE.locationAddressFull}
								</span>
							</li>
							<li className="flex items-center space-x-3">
								<Image
									src={ticketIcon}
									alt="Ticket icon"
									aria-hidden="true"
									className="size-6 shrink-0"
								/>
								<span className="font-normal text-white">
									£40/month
								</span>
							</li>
						</ul>
						<Separator orientation="horizontal" />
						<CalButton
							namespace={classItem.calEventNamespace}
							btnText={'Book Free Trial Class'}
						/>
					</div>
				))}
			</m.div>
		</MotionWrapper>
	);
};

export default ClassesTable;
